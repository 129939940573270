import { NavLink, useLocation } from 'react-router-dom'
import { Collapse, Nav, NavItem } from 'reactstrap'

const NavigationMenu = (props: any) => {
  const location = useLocation()
  const splitLocation = location?.pathname.split("-");

  return (
    <Collapse isOpen={props?.isOpen} navbar>
      <Nav className="me-auto" navbar>
        {props.navItems.map((item: any) => (
          <NavItem key={item.key}>
            <NavLink
              className={`${splitLocation.length > 0 && splitLocation[1] === item.key ? "active" : ""
                }`}
              to={item.path}
            >
              {item.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </Collapse>
  )
}

export default NavigationMenu