import React from "react";
import { useApi } from "../../../helper/apiHook";
import { Card, Col, Row } from "reactstrap";

const BookingClient = () => {
  const bookingList = useApi("/booking", true, {});
  console.log(bookingList.data, "bookingList");
  return (
    <div>
      <Row className="card-list">
        {bookingList?.data &&
          Array.isArray(bookingList?.data) &&
          bookingList.data.map((x: any) => {
            console.log(x, "xx");
            return (
              <Col md={6} lg={4} sm={12} xl={3}>
                <Card className="cursor-pointer">
                  <div className="card-content">
                    <div className="content-head-box">{x.bookingNo}</div>
                    <div className="content-head-box">{x.client?.businessName}</div>
                    <div className="content-head-box">{x.bookedByPersonName}</div>
                    <div className="content-head-box">{x.bookedByPersonMobile}</div>
                    <div className="content-head-box">{x.bookedByPersonEmail}</div>
                  </div>
                </Card>
              </Col>
            );
          })}
        {/* {mountingList?.data &&
          mountingList?.data.length > 0 &&
          mountingList?.data?.map((mountings: any) => {
            const parseMountingImages = mountings?.mountingImages
              ? JSON.parse(mountings?.mountingImages)
              : null;
            return (
              <Col
                md={6}
                lg={4}
                sm={12}
                xl={3}
              >
                <div className="pb-2">
                  <Button
                    onClick={() => {
                      props.setActiveBooking(null);
                      navigate("/mob-mounting", {
                        state: {
                          from: getStatusActiveTab(mountings.status),
                          activeBooking: null, // Pass the ID here
                        },
                      });
                    }}
                    className="hamburger-btn p-0"
                  >
                    {leftSquareArrow.icon}
                  </Button>
                  <span style={{ marginLeft: "10px" }}>
                    Booking No: {mountings?.bookingNo}
                  </span>
                </div>
                <Card className="cursor-pointer">
                  <ProtectedImage value={parseMountingImages?.url} />
            
                  <div className="card-content">
                    <div className="content-head-box">
                      <div>
             
                        <h3
                          className="d-flex gap-2"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            let locationUrl = `https://www.google.com/maps?q=${mountings?.mediaItem?.latitude},${mountings?.mediaItem?.longitude}`;
                            window.open(locationUrl, "_blank");
                          }}
                        >
                          <span>{LocationIcon.icon} </span>{" "}
                          {mountings?.mediaItem?.routeId
                            ? mountings?.mediaItem?.route
                                ?.startLandmarkDescription
                            : mountings?.mediaItem?.location?.name
                            ? mountings?.mediaItem?.location?.name
                            : "Location"}
                        </h3>
                      </div>
                      <div>
                        <span className="task-number">
                          Task no: {mountings?.taskNo}
                        </span>
                        <span className="poster-size-box">
                          {`${mountings?.mediaItem?.width} * ${mountings?.mediaItem?.height}`}{" "}
                          ft
                        </span>
                      </div>
                    </div>
                    <div className="content-action">
                      <div className="status-box">
                        <SelectStatus value={mountings} api={mountingList} />
                        {mountings?.status !== "mounted" ||
                        props?.activeTab === "all" ? (
                          <Link
                            to={`/mob-mounting/${props.activeBooking}`}
                            state={{ activeTab: props.activeTab }}
                          >
                            <Button color="secondary">
                              {uploadIcon.icon} Add Mounting Photo
                            </Button>
                          </Link>
                        ) : (
                          <div className="content-action">
                            <Link
                              to={`/mob-mounting/${props.activeBooking}`}
                              state={{ activeTab: props.activeTab }}
                            >
                              <Button color="secondary" type="button" outline>
                                {viewIcon.icon}
                                View
                              </Button>
                            </Link>
                          </div>
                        )}
  
                       </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })} */}
      </Row>
    </div>
  );
};
export default BookingClient;
