import { Accordion, Button, Col, Row } from "reactstrap";
import { Form, Formik } from "formik";
import { validationSchema } from "./validation";
import PageLayout from "../../components/layout/page";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useMemo, useState } from "react";
import Details from "./component/details";
import SoahTable from "./component/soah";
import PoahTable from "./component/poah";
import FpmpkTable from "./component/fpmpk";
import FbphTable from "./component/fbph";
import FbpTable from "./component/fbm";
import SoapkTable from "./component/soapk";
import { useApi } from "../../helper/apiHook";
import { Booking } from "./dto";
import { GUJARAT_GST_CODE } from "../../constant/commonConst";
import { MediaItemsApi } from "../masters/items";
import { message } from "../../components/toast";
import { groupByMediaItemId } from "./common";

const AddUpdateBooking = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  let body = undefined;
  let mediaItemsData = MediaItemsApi(false, { revalidateIfStale: true }, body);
  const dtlAPI = useApi("/booking", false, { id: value.id }, { revalidateIfStale: true });
  const isEdit = value.id > 0 ? "Update" : "Save"
  const tempJSON = isEdit ? dtlAPI.data?.mediaInventory : []

  let copiedRowAllData: any[] = [];
  if (isEdit === "Update") {
    copiedRowAllData = []
    if (copiedRowAllData.length === 0) {
      copiedRowAllData = structuredClone(tempJSON);
    }
  }

  const isExistsQty = copiedRowAllData && copiedRowAllData?.reduce(
    (acc: number, item: any) => acc + (Number(item.qty) || 0),
    0
  );

  const [open, setOpen] = useState<any>("1");
  const toggle = (id: number | any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  const initialValues = useMemo(() => {
    if (dtlAPI.data) {
      return {
        id: dtlAPI.data.id,
        clientId: dtlAPI.data.clientId,
        userId: dtlAPI.data.userId,
        bookingDate: dtlAPI.data.bookingDate || new Date(),
        bookedByPersonEmail: dtlAPI.data.bookedByPersonEmail,
        bookedByPersonMobile: dtlAPI.data.bookedByPersonMobile,
        bookedByPersonName: dtlAPI.data.bookedByPersonName,
        description: dtlAPI.data.description,
        bookingStartDate: dtlAPI.data.bookingStartDate || new Date(),
        bookingNo: dtlAPI.data.bookingNo,
        proposalId: dtlAPI.data.proposalId,
        status: dtlAPI.data.status,
        billingAddress: dtlAPI.data.billingAddress,
        GSTIN: "",
        inventoryIds: [],
        mediaInventory: groupByMediaItemId(dtlAPI.data?.mediaInventory, dtlAPI, mediaItemsData),
      } as Booking
    }
    else {
      return {} as Booking
    }
  }, [dtlAPI.data])

  const validateForm = (values: any) => {
    const totalRemainsQty =
      (values.mediaInventory[0]?.totalqty || 0) -
      (values.mediaInventory[0]?.bookedqty || 0);

    if (values.mediaInventory[0]?.groupCode === "SOAPK") {
      let errors: any = {};
      if (values.mediaInventory && Array.isArray(values.mediaInventory)) {
        const totalQtyUsed = values.mediaInventory.reduce(
          (acc: number, item: any) => acc + (Number(item.qty) || 0),
          0
        );

        // let totalRemainValues = 0
        // if (isEdit === "Save") {
        //   totalRemainValues = totalRemainsQty
        // } else if (isEdit === "Update") {
        //   totalRemainValues = totalRemainsQty + isExistsQty
        // }
        const totalRemainValues = (isEdit === "Save" ? totalRemainsQty : (totalRemainsQty + isExistsQty))
        if (totalQtyUsed > totalRemainValues) {
          const exceededBy = totalQtyUsed - totalRemainValues;
          errors.mediaInventory = `Total qty exceeds available quantity by ${exceededBy}. You have only ${totalRemainValues}.`;
        }
      }
      return Object.keys(errors).length ? errors : undefined;
    };
  }

  return (
    <PageLayout
      View={!dtlAPI.isLoading && !dtlAPI.isValidating && (
        <Formik
          validate={validateForm}
          validationSchema={validationSchema}
          enableReinitialize={false}
          initialValues={initialValues}
          onSubmit={(values: any, actions: any) => {
            let popArray: any[] = [];
            const isMediaItemIdValid = values.mediaInventory.every(
              (item: any) =>
                item.hasOwnProperty("mediaItemId") &&
                item.mediaItemId !== null &&
                item.mediaItemId !== ""
            );
            if (!isMediaItemIdValid) {
              message("error", "Please select media item for each inventory");
            } else {
              // Transform and filter the media inventory
              const filteredMediaInventory = values.mediaInventory
                .map((x: any) => {
                  let {
                    tempRowId,
                    agencyAmount,
                    city,
                    mediaLitType,
                    mediaItem,
                    mediaSeries,
                    zonedesc,
                    location,
                    bookingId,
                    sqft,
                    size,
                    day,
                    updateDate,
                    updateBy,
                    createDate,
                    createBy,
                    mediaType,
                    bookedqty = "",
                    totalqty = "",
                    isHaveSubMediaInventory, disableQty,
                    ...mediaInventoryItem
                  } = x;
                  if (isHaveSubMediaInventory) {
                    x.subMediaInventory = x.subMediaInventory.map(
                      (zx: any) => {
                        let {
                          tempRowId,
                          agencyAmount,
                          city,
                          mediaLitType,
                          mediaSeries,
                          zonedesc,
                          location,
                          bookingId,
                          sqft,
                          size,
                          day,
                          updateDate,
                          updateBy,
                          createDate,
                          createBy,
                          mediaType,
                          isHaveSubMediaInventory,
                          mediaItem,
                          disableQty,
                          bookedqty = "",
                          totalqty = "",
                          ...mediaInventoryItemX
                        } = zx;
                        return mediaInventoryItemX;
                      }
                    );
                    popArray = popArray.concat(x.subMediaInventory);
                    return null;
                  } else {
                    let { subMediaInventory, ...mediaInventoryItemRock } =
                      mediaInventoryItem;
                    return mediaInventoryItemRock;
                  }
                })
                .filter((item: any) => item !== null); // Filter out `null` values

              // Combine the filtered media inventory with popArray
              const combinedArray = popArray.concat(filteredMediaInventory);
              values.mediaInventory = combinedArray;
              const { tempRowId, bookingNo, GSTIN, ...valuesX } = values;
              delete values.GSTIN;
              if (values.id) {
                api.updateValue(valuesX).then((result: any) => {
                  if (result?.status) {
                    closeAddUpdate();
                    copiedRowAllData = []
                  }
                });
              } else {
                delete values.inventoryIds;
                api.create(values).then((result: any) => {
                  if (result?.status) {
                    closeAddUpdate();
                    copiedRowAllData = []
                  }
                });
              }
            }
          }}
        >
          {({ setFieldValue, values, errors }) => {
            const SubTotalAmount = Math.round((values.mediaInventory.reduce((acc, item) => {
              if (Array.isArray(item?.subMediaInventory) && item?.subMediaInventory.length > 1) {
                return acc + (item.subMediaInventory?.reduce((acc: number, current: any) => {
                  acc += current?.amount || 0;
                  return acc;
                }, 0) || 0);
              }
              return acc + (item.amount || 0)
            }, 0) + Number.EPSILON) * 100) / 100;
            const igstAmount = Math.round((SubTotalAmount * 0.18 + Number.EPSILON) * 100) / 100;
            const cgstAmount = Math.round((SubTotalAmount * 0.09 + Number.EPSILON) * 100) / 100;
            const sgstAmount = Math.round((SubTotalAmount * 0.09 + Number.EPSILON) * 100) / 100;
            const isGujaratGstNo = values?.GSTIN ? values?.GSTIN?.substring(-2) == GUJARAT_GST_CODE ? true : false : false;
            const totalAmount = Math.round(((isGujaratGstNo ? SubTotalAmount + igstAmount : SubTotalAmount + cgstAmount + sgstAmount) + Number.EPSILON) * 100) / 100;
            return (
              <UpdateForm
                value={value}
                setFieldValue={setFieldValue}
                values={values}
                toggle={toggle}
                open={open}
                igstAmount={igstAmount}
                isGujaratGstNo={isGujaratGstNo}
                SubTotalAmount={SubTotalAmount}
                closeAddUpdate={closeAddUpdate}
                totalAmount={totalAmount}
                sgstAmount={sgstAmount}
                cgstAmount={cgstAmount}
                errors={errors}
              />
            );
          }}
        </Formik>
      )
      }
    />
  );
};

const UpdateForm = (props: any) => {
  const { value, setFieldValue, values, toggle, open, igstAmount, isGujaratGstNo, SubTotalAmount, closeAddUpdate, totalAmount, sgstAmount, cgstAmount, errors, } = props

  const formsValues = useMemo(() => {
    return <Form className="form-main add-booking">
      <Details
        proposal={false}
        setFieldValue={setFieldValue}
        values={values}
      />
      <Accordion open={open} toggle={toggle}>
        <SoahTable
          bookingStartDate={values.bookingStartDate}
          setFieldValue={setFieldValue}
          mediaInventory={values.mediaInventory}
          isEditMode={value?.id}
        />
        <PoahTable
          bookingStartDate={values.bookingStartDate}
          setFieldValue={setFieldValue}
          mediaInventory={values.mediaInventory}
          isEditMode={value?.id}
        />
        <FbphTable
          bookingStartDate={values.bookingStartDate}
          setFieldValue={setFieldValue}
          mediaInventory={values.mediaInventory}
          isEditMode={value?.id}
        />
        <FbpTable
          bookingStartDate={values.bookingStartDate}
          setFieldValue={setFieldValue}
          mediaInventory={values.mediaInventory}
          isEditMode={value?.id}
        />
        <SoapkTable
          bookingStartDate={values.bookingStartDate}
          setFieldValue={setFieldValue}
          mediaInventory={values.mediaInventory}
          isEditMode={value?.id}
        />
        <FpmpkTable
          bookingStartDate={values.bookingStartDate}
          setFieldValue={setFieldValue}
          mediaInventory={values.mediaInventory}
          isEditMode={value?.id}
        />
      </Accordion>


      <Row className="add-booking-footer">
        <Col>
          <div className="total-amount">
            <label>Amount: {SubTotalAmount}</label>
            {isGujaratGstNo ? (
              <label>
                IGST<strong> (18%):</strong> {igstAmount}
              </label>
            ) : (
              <>
                <label>
                  CGST<span>(9%):</span> {cgstAmount}
                </label>
                <label>
                  SGST<span>(9%):</span> {sgstAmount}
                </label>
              </>
            )}

            <label>
              Total amount :<strong> {totalAmount}</strong>
            </label>
          </div>
        </Col>
        {
          values.mediaInventory[0]?.groupCode === "SOAPK" && errors?.mediaInventory && (
            <p style={{ color: "red", fontSize: "12px" }}>
              {errors.mediaInventory}
            </p>
          )}
        <Col lg="12" className="d-flex justify-content-end gap-2">
          <Button
            color="secondary"
            outline
            type="button"
            onClick={closeAddUpdate}
          >
            Cancel
          </Button>
          <Button
            disabled={values.mediaInventory[0]?.groupCode === "SOAPK" && errors?.mediaInventory}
            color="secondary"
            solid
            type="submit"
            onClick={() =>
              setFieldValue("totalAmount", totalAmount)
            }
          >
            {value?.id > 0 ? "Update" : "Save"}
          </Button>
        </Col>
      </Row>
    </Form>
  }, [open, JSON.stringify(values)])
  return formsValues
}

export { AddUpdateBooking };
