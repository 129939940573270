import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AppLogo } from '../../constant/logo'
import NavigationMenu from '../../pages/mobileView/components/NavigationMenu'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavbarToggler } from 'reactstrap'
import { useAuth } from '../auth'
import { logoutDuotone } from '../../constant/icons'
import { getShortUserName, getValueByKey } from '../../utils/generalUtils'

const ClientHeader = () => {
  const auth = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);

  const togglemenu = () => setIsOpen(!isOpen);
  const username = getValueByKey("username");
  const navItems = [
    { path: "/home", label: "Booking", key: "booking" },
    { path: "/mob-unmounting", label: "Un-Mounting", key: "unmounting" },
  ];
  return (
    <div className="header navbar sticky-top">
      <div className="left-panel">
        <Link to={"/"}>
          {AppLogo.main}
        </Link>
      </div>
      <div className="right-panel">
        <NavigationMenu isOpen={isOpen} navItems={navItems} />
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="profile-main"
        >
          <DropdownToggle color="link">
            <span>
              {username ? getShortUserName(username).toUpperCase() : ""}
            </span>
            <h6>
              {username}
              {/*<small>{auth.user?.role}</small> */}
            </h6>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => auth.logOut()}>
              <span>{logoutDuotone.icon}</span>
              <p>
                Log Out
                <small></small>
              </p>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <NavbarToggler onClick={togglemenu} />
      </div>
    </div>
  )
}

export default ClientHeader