import React from "react";
import { Outlet } from "react-router-dom";
import ClientHeader from "./UserLayout";

const ClientLayout = () => {
  return (
    <div className="front-page">
      <div className="container">
        <ClientHeader />
        <Outlet />
      </div>
    </div>
  );
};

export default ClientLayout;
