import { DataType } from "ka-table";
import { TableColumn } from "../../components/table/table";
import Media from "./component/media";
import Landloard from "./component/landloard";
import Booking from "./component/booking";
import { promises } from "dns";
import { boolean, number } from "yup";
import { useState } from "react";
import GoogleMapsLink, {
  GoogleMapsStartLink,
} from "../masters/route/component/GoogleMapsLink ";
import moment from "moment";

export const columns = (): TableColumn[] => {
  return [
    {
      key: "id",
      title: "Media",
      dataType: DataType.Number,
      component: Media,
    },
    {
      key: "booking",
      title: "Booking",
      dataType: DataType.Number,
      component: Booking,
    },
    {
      key: "landloard",
      title: "Landloard",
      dataType: DataType.Number,
      component: Landloard,
    },
  ];
};

export const mediaColumns = (actions: any, tabId: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "mediaName",
      title: "Media ID",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
    },
    {
      key: "routeName",
      title: "Media Route Id",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "zoneName",
      title: "Zone Id",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "city",
      title: "City",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.city?.name;
      },
    },
    {
      key: "zoneDescription",
      title: "Zone Description",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "startLandmarkDescription",
      title: "Starting Point Landmark",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "endLandmarkDescription",
      title: "Ending Point Landmark ",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "qty",
      title: "Pole Kiosks Qty",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "",
      title: "Available Pole Kiosks Qty",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.qty - rowData?.totalBookedQty;
      },
    },
    {
      key: "location",
      title: "Location",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.location?.name;
      },
    },
    {
      key: "width",
      title: "Size [ Sq.ft ] Width",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
    },
    {
      key: "height",
      title: "Size [ Sq.ft ] Height",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
    },
    {
      key: "totalSqFt",
      title: "Total Sq.ft",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.height * rowData?.width;
      },
    },
    {
      key: "mediaType",
      title: "Media Type",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.mediaType?.name;
      },
    },
    {
      key: "mediaSeriesName",
      title: "Media Series",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
    },
    {
      key: "mediaLITName",
      title: "Media LIT",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.mediaType?.type;
      },
    },
    {
      key: "rateAgency",
      title: "Agency Card Rate",
      dataType: DataType.String,
    },
    {
      key: "routeLink",
      title: "Route Link",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return GoogleMapsStartLink({
          startLat: rowData?.startLatitude,
          endLng: rowData?.endLatitude,
        });
      },
    },
    {
      key: "startLatitude",
      title: "Starting Point Landmark Latitude And Longitude",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.startLatitude + " - " + rowData?.startLongitude;
      },
    },
    {
      key: "endLatitude",
      title: "Ending Point Landmark Latitude And Longitude",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.endLatitude + " - " + rowData?.endLongitude;
      },
    },
    {
      key: "IsActivate",
      title: "Media Status",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.totalBookedQty == 0 ? "Available" : "Book";
      },
    },
    {
      key: "locationLink",
      title: "Media Location Link",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return GoogleMapsStartLink({
          startLat: rowData?.locationLatitude,
          endLng: rowData?.locationLongitude,
        });
      },
    },
    {
      key: "",
      title: "Media Image",
      dataType: DataType.String,
      visible: false,
    },
    {
      key: "agencyName",
      title: "Agency",
      dataType: DataType.String,
    },
  ];
};
export const bookingColumns = (actions: any): TableColumn[] => {
  return [
    {
      key: "bookingId",
      title: "Booking ID",
      dataType: DataType.String,
    },
    {
      key: "clientName",
      title: "Client / Campaign Name",
      dataType: DataType.String,
    },
    {
      key: "bookingDateStart",
      title: "Date Start",
      dataType: DataType.String,
    },
    {
      key: "bookingEndDate",
      title: "Date End",
      dataType: DataType.String,
    },
    {
      key: "durationMonth",
      title: "Duration Month",
      dataType: DataType.String,
    },
    {
      key: "durationDay",
      title: "Duration Days",
      dataType: DataType.String,
    },
    {
      key: "bookingrate",
      title: "Booking Rate",
      dataType: DataType.String,
    },
    {
      key: "rateAgency",
      title: "Agency Purchase Rate",
      dataType: DataType.String,
    },
  ];
};

export const landloardColumns = (actions: any): TableColumn[] => {
  return [
    {
      key: "landlordType",
      title: "Landlord Type",
      dataType: DataType.String,
    },
    {
      key: "RatePerYear",
      title: "Rate Per Year",
      dataType: DataType.String,
    },
    {
      key: "landlordStartDate",
      title: "Date Start",
      dataType: DataType.String,
    },
    {
      key: "landlordEndDate",
      title: "Date End",
      dataType: DataType.String,
    },
  ];
};
const MediaColor: string = "#494f54",
  bookingColor: string = "#92001e",
  landloar: string = "#0a95b1";
export const allDashboardColumns = (actions?: any,tabId?: any): TableColumn[] => {
  return [
    {
      key: "id",
      title: "id",
      dataType: DataType.Number,
      visible: false,
    },
    {
      key: "mediaName",
      title: "Media ID",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "routeName",
      title: "Media Route Id",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "zoneName",
      title: "Zone Id",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "city",
      title: "City",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.city?.name;
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "zoneDescription",
      title: "Zone Description",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "startLandmarkDescription",
      title: "Starting Point Landmark",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "endLandmarkDescription",
      title: "Ending Point Landmark ",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "qty",
      title: "Pole Kiosks Qty",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "qtyAvailable",
      title: "Available Pole Kiosks Qty",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        try{
          
           let bal=(Number(rowData?.qty) - Number(rowData?.totalBookedQty))
          return `${bal}`;
        }catch{

        }
        return 0;
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "location",
      title: "Location",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.location?.name;
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "width",
      title: "Size [ Sq.ft ] Width",
      dataType: DataType.String,
      // visible: tabId != 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "height",
      title: "Size [ Sq.ft ] Height",
      dataType: DataType.String,
      // visible: tabId != 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "totalSqFt",
      title: "Total Sq.ft",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.height * rowData?.width;
      },
      visible: tabId != 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "mediaType",
      title: "Media Type",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.mediaType?.name;
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "mediaSeriesName",
      title: "Media Series",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "mediaLITName",
      title: "Media LIT",
      dataType: DataType.String,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.mediaType?.type;
      },
      visible: tabId != 1 ? false : true,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "routeLink",
      title: "Route Link",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return GoogleMapsLink({
          startLat: rowData?.startLatitude,
          startLng: rowData?.startLongitude,
          endLat: rowData?.endLatitude,
          endLng: rowData?.endLongitude,
        });
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "startLatitude",
      title: "Starting Point Landmark Latitude And Longitude",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.startLatitude + " - " + rowData?.startLongitude;
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "endLatitude",
      title: "Ending Point Landmark Latitude And Longitude",
      dataType: DataType.String,
      visible: tabId == 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return rowData?.endLatitude + " - " + rowData?.endLongitude;
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "status",
      title: "Media Status",
      dataType: DataType.String,
      // valueConvert: (row: any, rowData: any) => {
      //   return rowData?.totalBookedQty == 0 ? "Available" : "Book";
      // },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "locationLink",
      title: "Media Location Link",
      dataType: DataType.String,
      visible: tabId != 1 ? false : true,
      valueConvert: (row: any, rowData: any) => {
        return GoogleMapsStartLink({
          startLat: rowData?.locationLatitude,
          endLng: rowData?.locationLongitude,
        });
      },
      style: { backgroundColor: MediaColor },
    },
    {
      key: "",
      title: "Media Image",
      dataType: DataType.String,
      visible: false,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "agencyName",
      title: "Agency",
      dataType: DataType.String,
      style: { backgroundColor: MediaColor },
    },
    {
      key: "bookingId",
      title: "Booking ID",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
    },
    {
      key: "clientName",
      title: "Client / Campaign Name",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
    },
    {
      key: "bookingDateStart",
      title: "Date Start",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
      valueConvert: (row: any, rowData: any) => {
        return rowData.bookingDateStart
          ? moment(rowData.bookingDateStart).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      key: "bookingEndDate",
      title: "Date End",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
      valueConvert: (row: any, rowData: any) => {
        return rowData.bookingEndDate
          ? moment(rowData.bookingEndDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      key: "durationMonth",
      title: "Duration Month",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
    },
    {
      key: "durationDay",
      title: "Duration Days",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
    },
    {
      key: "bookingrate",
      title: "Booking Rate",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
    },
    {
      key: "rateAgency1",
      title: "Agency Purchase Rate",
      dataType: DataType.String,
      style: { backgroundColor: bookingColor },
      valueConvert: (row: any, rowData: any) => {
        return rowData.agencyName !== undefined && rowData.agencyName !== null ? rowData.rateAgency : "";

      }
    },


    {
      key: "landlordType",
      title: "Landlord Type",
      dataType: DataType.String,
      style: { backgroundColor: landloar },
    },
    {
      key: "RatePerYear",
      title: "Rate Per Year",
      dataType: DataType.String,
      style: { backgroundColor: landloar },
    },
    {
      key: "landlordStartDate",
      title: "Date Start",
      valueConvert: (row: any, rowData: any) => {
        return rowData.landlordStartDate
          ? moment(rowData.landlordStartDate).format("DD/MM/YYYY")
          : "";
      },
      dataType: DataType.String,
      style: { backgroundColor: landloar },
    },
    {
      key: "landlordEndDate",
      title: "Date End",
      valueConvert: (row: any, rowData: any) => {
        return rowData.landlordEndDate
          ? moment(rowData.landlordEndDate).format("DD/MM/YYYY")
          : "";
      },
      dataType: DataType.String,
      style: { backgroundColor: landloar },
    },
  ];
};
