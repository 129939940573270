import { Navigate, redirect, Route, Routes, useNavigate } from "react-router-dom";
import { routes } from "../menu";
import { Layout } from "./layout/layout";
import Dashboard from "../pages/mobileView";
import TaskDtl from "../pages/mobileView/taskDtl";
import UnMountingDashboard from "../pages/unMounting/mobileView";
import UnMountingTaskDtl from "../pages/unMounting/mobileView/taskDtl";
import { getRole } from "../utils/generalUtils";
import { useAuth } from "./auth";
import MountingMobileView from "../pages/mounting/Components/MountingMobileView";
import { ROLE } from "../constant/commonConst";
import UnMountingMobileView from "../pages/unMounting/mobileView/UnMountingMobileView";
import ClientLayout from "./layout/clientLayout";
import BookingClient from "../pages/clientview/booking";
const RouteAccess = (props: any) => {
  let role = getRole();
  const auth = useAuth();
  if (!role) {
    auth.logOut();
  }
  console.log(role === ROLE.ADMIN, "roleroleroles")
  return (
    <Routes>
      {role === ROLE.ADMIN &&
        <Route element={<Layout />}>
          {routes.map(({ path, element }) => {
            return <Route key={path} path={path} element={<ProtectedRoute
              element={element}
              requiredCondition={role === ROLE.MOUNTINGUSER}
              role={role}
              path={path}
              redirectPath={role === ROLE.MOUNTINGUSER ? "/mob-mounting" : "/printing"}
            />} />
          }
          )}
        </Route>
      }

      <Route >
        <Route path="/mob-mounting" element={
          <ProtectedRoute
            element={<MountingMobileView />}
            requiredCondition={role !== ROLE.MOUNTINGUSER}
            redirectPath="/home"
          />} />
        <Route path="/mob-unmounting" element={<ProtectedRoute
          // element={<UnMountingDashboard />}
          element={<UnMountingMobileView />}
          requiredCondition={role !== ROLE.MOUNTINGUSER}
          redirectPath="/home"
        />} />
        <Route path="/mob-mounting/:id" element={<ProtectedRoute
          element={<TaskDtl />}
          requiredCondition={role !== ROLE.MOUNTINGUSER}
          redirectPath="/home"
        />} />
        <Route path="/mob-unmounting/:id" element={<ProtectedRoute
          element={<UnMountingTaskDtl />}
          requiredCondition={role !== ROLE.MOUNTINGUSER}
          redirectPath="/home"
        />} />
        {role === ROLE.CLIENT &&
          <Route element={<ClientLayout />}>
            <Route path="/home" element={<BookingClient/>} />
            
          </Route>
        }

        <Route path="*" element={<h1>PAGE NOT FOUND 404</h1>} />
      </Route>
    </Routes>
  );
};
export default RouteAccess;

const ProtectedRoute = ({ element, requiredCondition, redirectPath, role, path }: any) => {
  if (role == ROLE.PRINTINGUSER) {
    redirect(redirectPath)
    if (path == redirectPath) {
      return element;
    }
    return <Navigate to={redirectPath} />
  }
  if (requiredCondition) {
    return <Navigate to={redirectPath} />;
  }
  return element;
};