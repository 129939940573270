import React, { useEffect, useState } from "react";
import { useApi } from "../../../helper/apiHook";
import Dashboard, { MountingCardShow } from "../../mobileView";
import { TabPane } from "reactstrap";
import UsermobMountingDataView from "../../mobileView/components/UsermobMountingDataView";
import { components } from "react-select";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../components/auth";
import { getValueByKey } from '../../../utils/generalUtils';

export const GetAllMounting = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/mountings/getAll", paging, undefined, dataLoad) };
};
const MountingMobileView = () => {
  const location = useLocation();
  const mountingList = GetAllMounting(true);
  const { activeBooking } = location.state || {}; // Get activeBooking from state
  const [selectedBooking, setSelectedBooking] = useState(activeBooking); // Initialize with passed activeBooking
  const [activeTab, setActiveTab] = useState("waiting-for-creative");
  useEffect(() => {
    if (selectedBooking && activeTab) {
      setSelectedBooking(null);
    }
  }, [activeTab]);

  return (
    <Dashboard
      mountingList={mountingList}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {["mounting-pending", "mounted", "all"].map(
        (element, index) => {
          return (
            <TabPane key={index} tabId={element}>
              {selectedBooking ? (
                <MountingCardShow
                  activeBooking={selectedBooking}
                  setActiveBooking={setSelectedBooking}
                  filter={element}
                  activeTab={activeTab}
                />

              ) : (
                <UsermobMountingDataView
                  activeTab={activeTab}
                  setActiveBooking={setSelectedBooking}
                />
              )}
            </TabPane>
          );
        }
      )}
    </Dashboard>
  );
};

export default MountingMobileView;
