import React, { useState, useEffect, useMemo } from "react";
import PageLayout from "../../components/layout/page";
import { Button, Card, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { DataView } from "../../components/table/table";
import { allDashboardColumns, columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { downloadIcon, filterIcon, searchIcon } from "../../constant/icons";
import Dropdown from "../../components/common/dropdown";
import TextBox from "../../components/common/textBox";
import { Formik, Form } from "formik";
import { DashboardFilter } from "./component/filter";
import { CityApi } from "../masters/city";
import { MediaTypeApi } from "../masters/mediatype";
import { MediaLitApi } from "../masters/littype";
import ReactSelect from "react-select";
import { GET, POST, PUT } from "../../constant/commonConst";
import { invokeApi } from "../../helper/api";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { date } from "yup";
import ExcelJS from 'exceljs';
import { HomeDataView } from "../../components/table/tableHome";

// Define the shape of your filter state
interface FilterValues {
  cityIds?: number[];
  mediaTypes?: number[];
  mediaTypesLit?: number[];
  mediaStatus?: string;
  [key: string]: any; // Allows for additional dynamic properties
}

// Define the shape of your pagination state
interface PaginationType {
  skip: number;
  take: number;
  limit?: number;
  pageNo?: number;
}

export default function Dashboard() {
  const [tab, setTab] = useState<string>("1");
  console.log(tab, "tab")
  const [filter, setFilter] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<FilterValues>({});
  const [pagination, setPagination] = useState<PaginationType>({
    skip: 0,
    take: 10,
    limit: 10,
    pageNo: 0,
  });
  const [api, setApi] = useState<any>({ data: [] });
  const [tableRef, setTableRef] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState("");

  const updateFilterValue = (newValue: Partial<FilterValues>) => {
    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      ...newValue,
    }));
  };

  const tabValue = tab === "1" ? "HORDINGS" : "KIOS";

  const callApi = async (paging?: any) => {
    const cleanFilterValue = Object.fromEntries(
      Object.entries(filterValue).filter(
        ([_, value]) => value !== undefined && value !== null && value.length !== 0
      )
    );
    const apiCallData = await invokeApi(POST, "/mediaItems/findAll", {

      forDashbord: true,
      dashboardTab: tabValue,
      ...cleanFilterValue,
      ...paging,
    });
    const apiCall = Array.isArray(apiCallData?.data) ? apiCallData : apiCallData?.data
    if (apiCall?.data) {
      const updatedData = {
        ...apiCall,
        tabId: Number(tab) // Replace with the actual value or variable
      };
      setApi(updatedData);
      setTableRef(true);
    }
    // let mappedData;
    // if (Number(tab) === 1) {
    //   mappedData = apiCall?.data?.map((item: any) => ({
    //     'Media ID': item.mediaName,  // Replace with your own key mappings
    //     'City': item.city,
    //     'Location': item.location,
    //     'Size [ Sq.ft ] Width': item.width,
    //     'Size [ Sq.ft ] Height': item.height,
    //     'Total Sq.ft': item.totalSqFt,
    //     'Media Type': item.mediaType,
    //     'Media LIT': item.mediaLITName,
    //     'Media Status': item.IsActivate,
    //     'Media Location Link': item.locationLink,
    //     'Agency': item.agencyName,
    //     // 'Booking ID': item.bookingId,
    //     // 'Client / Campaign Name': item.clientName,
    //     // 'Booking Date Start': item.bookingDateStart,
    //     // 'Booking Date End': item.bookingEndDate,
    //     // 'Duration Month': item.durationMonth,
    //     // 'Duration Days': item.durationDay,
    //     // 'Booking Rate': item.bookingrate,
    //     // 'Agency Purchase Rate': item.agencyPurchaseRate,

    //     // 'Landlord Type': item.landlordType,
    //     // 'Rate Per Year': item.RatePerYear,
    //     // 'Landloard Date Start': item.landlordStartDate,
    //     // 'Landloard Date End': item.landlordEndDate
    //   }));
    // } else {
    //   mappedData = apiCall?.data?.map((item: any) => ({
    //     'Media Route ID': item.mediaName,  // Replace with your own key mappings
    //     'Zone Id': item.zoneName,
    //     'City': item.city,
    //     'Zone Description': item.zoneDescription,
    //     'Start Landmark Description': item.startLandmarkDescription,
    //     'End Landmark Description': item.endLandmarkDescription,
    //     'Pole Kiosks Qty': item.qty,
    //     'Available Pole Kiosks Qty': item.qty,
    //     'Size [ Sq.ft ] Width': item.width,
    //     'Size [ Sq.ft ] Height': item.height,

    //     'Media Type': item.mediaType,
    //     'Media Series': item.mediaSeriesName,
    //     'Media Status': item.IsActivate,
    //     'Agency Card Rate': item.rateAgency,
    //     'Route Link': item.routeLink,
    //     'Starting Point Landmark Latitude And Longitude': item.startLatitude,
    //     'Ending Point Landmark Latitude And Longitude': item.endLatitude,

    //     'Agency': item.agencyName,
    //     // 'Booking ID': item.bookingId,
    //     // 'Client / Campaign Name': item.clientName,
    //     // 'Booking Date Start': item.bookingDateStart,
    //     // 'Booking Date End': item.bookingEndDate,
    //     // 'Duration Month': item.durationMonth,
    //     // 'Duration Days': item.durationDay,
    //     // 'Booking Rate': item.bookingrate,

    //     // 'Landlord Type': item.landlordType,
    //     // 'Rate Per Year': item.RatePerYear,
    //     // 'Landloard Date Start': item.landlordStartDate,
    //     // 'Landloard Date End': item.landlordEndDate
    //   }));
    // }
    let mappedData;

    if (Number(tab) === 1) {
      mappedData = apiCall?.data?.map((item: any) => ({
        mediaId: item.mediaName,  // Example camel case conversion
        city: item.city,
        location: item.location,
        sizeSqFtWidth: item.width,
        sizeSqFtHeight: item.height,
        totalSqFt: item.width * item.height,
        mediaType: item.mediaType,
        mediaLit: item.mediaLITName,
        mediaStatus: item?.status,
        mediaLocationLink: `https://www.google.com/maps?q=${item?.locationLongitude},${item?.locationLongitude}`,
        agency: item.agencyName,
        // bookingId: item.bookingId,
        // clientCampaignName: item.clientName,
        // bookingDateStart: item.bookingDateStart,
        // bookingDateEnd: item.bookingEndDate,
        // durationMonth: item.durationMonth,
        // durationDays: item.durationDay,
        // bookingRate: item.bookingrate,
        // agencyPurchaseRate: item.agencyPurchaseRate,
        // landlordType: item.landlordType,
        // ratePerYear: item.RatePerYear,
        // landlordDateStart: item.landlordStartDate,
        // landlordDateEnd: item.landlordEndDate
      }));
    } else {
      mappedData = apiCall?.data?.map((item: any) => ({
        mediaRouteId: item.mediaName,  // Example camel case conversion
        zoneId: item.zoneName,
        city: item.city,
        zoneDescription: item.zoneDescription,
        startLandmarkDescription: item.startLandmarkDescription,
        endLandmarkDescription: item.endLandmarkDescription,
        poleKiosksQty: item.qty,
        availablePoleKiosksQty: item.qty,
        sizeSqFtWidth: item.width,
        sizeSqFtHeight: item.height,
        mediaType: item.mediaType,
        mediaSeries: item.mediaSeriesName,
        mediaStatus: item?.totalBookedQty == 0 ? "Available" : "Book",
        agencyCardRate: item.rateAgency,
        routeLink: `https://www.google.com/maps/dir/?api=1&origin=${item.startLatitude},${item.startLongitude}&destination=${item.endLatitude},${item.endLongitude}&travelmode=driving`,
        startingPointLandmarkLatitudeAndLongitude: item.startLatitude + " - " + item.startLongitude,
        endingPointLandmarkLatitudeAndLongitude: item.endLatitude + " - " + item.endLongitude,
        agency: item.agencyName,
        // bookingId: item.bookingId,
        // clientCampaignName: item.clientName,
        // bookingDateStart: item.bookingDateStart,
        // bookingDateEnd: item.bookingEndDate,
        // durationMonth: item.durationMonth,
        // durationDays: item.durationDay,
        // bookingRate: item.bookingrate,
        // landlordType: item.landlordType,
        // ratePerYear: item.RatePerYear,
        // landlordDateStart: item.landlordStartDate,
        // landlordDateEnd: item.landlordEndDate
      }));
    }
    return mappedData
  };
  useEffect(() => {
    callApi(pagination);
  }, [filterValue, tab, pagination]);

  const apiData = CityApi(false);
  const apiDataList: any[] = useMemo(
    () => apiData?.data?.data?.length > 0
      ? apiData.data.data.map((item: any) => ({ value: item.id, label: item.name }))
      : [],
    [apiData]
  );

  const mediaTypeApiData = MediaTypeApi(false);
  const mediaApiDataList: any[] = useMemo(
    () => mediaTypeApiData?.data?.data?.length > 0
      ? mediaTypeApiData.data.data.map((item: any) => ({ value: item.id, label: item.name }))
      : [],
    [mediaTypeApiData]
  );

  const mediaLitApiApiData = MediaLitApi(false);
  const mediaLitApiDataList: any[] = useMemo(
    () => mediaLitApiApiData?.data?.data?.length > 0
      ? mediaLitApiApiData.data.data.map((item: any) => ({ value: item.id, label: item.name }))
      : [],
    [mediaLitApiApiData]
  );

  const mediaStatusOptions = [
    { value: 'Booked', label: 'Booked' },
    { value: 'Blocked', label: 'Blocked' },
    { value: 'Available', label: 'Available' }
  ];

  const handleShowFilter = () => {
    setFilter(!filter);
  };

  let setWidth: any[] = api?.data?.length
    ? Array.from(new Set(api.data.map((item: any) => item.width)))
      .map((width) => ({ value: width, label: width }))
    : [];

  let setHeight: any[] = api?.data?.length
    ? Array.from(new Set(api.data.map((item: any) => item.height)))
      .map((height) => ({ value: height, label: height }))
    : [];

  // const exportToExcel = async () => {
  //   const mappedData = await callApi(pagination); // Ensure you await the async function
  //   const worksheet = XLSX.utils.json_to_sheet(mappedData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   saveAs(blob, Date() + '.xlsx');
  // };
  const dashboardPPTDownload = async () => {
    const response = await invokeApi(PUT, `/proposals/generate/1`, {
      isDashboard: true,
      tab: tabValue,
      mediaData: JSON.stringify(api),
    }, undefined, { responseType: "blob" }
    );

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    const timestamp = new Date();
    link.setAttribute("download", `dashboard-${timestamp}.ppt`);
    link.click();
    link.remove();
  }
  const headersPole = [
    { header: 'Media Route Id', key: 'mediaRouteId', width: 25 },
    { header: 'Zone Id', key: 'zoneId', width: 25 },
    { header: 'City', key: 'city', width: 25 },
    { header: 'Zone Description', key: 'zoneDescription', width: 30 },
    { header: 'Starting Point Landmark', key: 'startLandmarkDescription', width: 30 },
    { header: 'Ending Point Landmark', key: 'endLandmarkDescription', width: 30 },
    { header: 'Pole Kiosks Qty', key: 'poleKiosksQty', width: 25 },
    { header: 'Available Pole Kiosks Qty', key: 'availablePoleKiosksQty', width: 30 },
    { header: 'Size [ Sq.ft ] Width', key: 'sizeSqFtWidth', width: 15 },
    { header: 'Size [ Sq.ft ] Height', key: 'sizeSqFtHeight', width: 15 },

    { header: 'Media Series', key: 'mediaSeries', width: 25 },
    { header: 'Route Link', key: 'routeLink', width: 40 },
    { header: 'Starting Point Landmark Latitude And Longitude', key: 'startingPointLandmarkLatitudeAndLongitude', width: 30 },
    { header: 'Ending Point Landmark Latitude And Longitude', key: 'endingPointLandmarkLatitudeAndLongitude', width: 30 },
    { header: 'Media Status', key: 'mediaStatus', width: 25 },
    { header: 'Agency', key: 'agency', width: 25 },

  ];
  const exportToExcel = async () => {
    const mappedData = await callApi(pagination); // Await the callApi function to get data

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    // Define headers
    const headers = [
      { header: 'Media ID', key: 'mediaId', width: 25 },
      { header: 'City', key: 'city', width: 25 },
      { header: 'Location', key: 'location', width: 30 },
      { header: 'Size [ Sq.ft ] Width', key: 'sizeSqFtWidth', width: 20 },
      { header: 'Size [ Sq.ft ] Height', key: 'sizeSqFtHeight', width: 20 },
      { header: 'Total Sq.ft', key: 'totalSqFt', width: 20 },
      { header: 'Media Type', key: 'mediaType', width: 20 },
      { header: 'Media LIT', key: 'mediaLit', width: 20 },
      { header: 'Media Status', key: 'mediaStatus', width: 20 },
      { header: 'Media Location Link', key: 'mediaLocationLink', width: 30 },
      { header: 'Agency', key: 'agency', width: 25 },
      // { header: 'Booking ID', key: 'bookingId', width: 20 },
      // { header: 'Client / Campaign Name', key: 'clientCampaignName', width: 30 },
      // { header: 'Date Start', key: 'bookingDateStart', width: 20 },
      // { header: 'Date End', key: 'bookingDateEnd', width: 20 },
      // { header: 'Duration Month', key: 'durationMonth', width: 20 },
      // { header: 'Duration Days', key: 'durationDays', width: 20 },
      // { header: 'Booking Rate', key: 'bookingRate', width: 20 },
      // { header: 'Agency Purchase Rate', key: 'rateAgency', width: 20 },
      // { header: 'Landlord Type', key: 'landlordType', width: 20 },
      // { header: 'Rate Per Year', key: 'ratePerYear', width: 20 },
      // { header: 'Landlord Date Start', key: 'landlordDateStart', width: 20 },
      // { header: 'Landlord Date End', key: 'landlordDateEnd', width: 20 }
    ];

    // Assuming mappedData is an array of objects where each object represents a row
    if (mappedData.length > 0) {
      // Add headers with custom styles
      worksheet.columns = tab === "1" ? headers : headersPole

      // Apply styling to the header row (first row)
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF000000' },
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center align
      });

      // Add rows with data
      mappedData.forEach((dataRow: any) => {
        worksheet.addRow(dataRow); // Add each row to the worksheet
      });

      // Auto-fit column widths based on content
      worksheet.columns.forEach((column: any) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell: any) => {
          const cellValue = cell.value ? cell.value.toString().length : 0;
          if (cellValue > maxLength) {
            maxLength = cellValue;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength; // Minimum width of 10
      });
    }

    // Create buffer for excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Use saveAs to download the file
    saveAs(blob, `${tabValue}_${new Date().toISOString()}.xlsx`);
  };
  return (
    <PageLayout className="dashboard-inner-page"
      View={
        <>
          <div className="d-flex justify-content-between align-items-end">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={tab === "1" ? "active" : ""}
                  onClick={() => setTab("1")}
                >
                  Hoardings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab === "2" ? "active" : ""}
                  onClick={() => setTab("2")}
                >
                  Pole Kios
                </NavLink>
              </NavItem>
            </Nav>
            <div className="head-btn-box">
              <div className="head-search-box d-flex justify-content-between">
                <div className="search_box">
                  <Button color="link" className="p-0">
                    {searchIcon.icon}
                  </Button>
                  <input
                    name="searchbox"
                    placeholder="Search.."
                    type="search"
                    value={searchValue}
                    onChange={(event) => {
                      setSearchValue(event.currentTarget.value);
                    }}
                    className="top-element"
                  />
                </div>
              </div>
              <Button color="secondary" onClick={dashboardPPTDownload}>
                {downloadIcon.icon}PPT Download
              </Button>
              <Button color="secondary" type="button" outline onClick={exportToExcel}>
                {downloadIcon.icon}
                Download
              </Button>
              <Button
                color="secondary"
                type="button"
                outline
                onClick={handleShowFilter}
              >
                {filterIcon.icon}
                Filter
              </Button>
            </div>
          </div>
          {filter && (
            <DashboardFilter
              cityList={apiDataList}
              filtervalue={filterValue}
              mediaApiDataList={mediaApiDataList}
              mediaLitApiDataList={mediaLitApiDataList}
              mediaStatusOptions={mediaStatusOptions}
              setWidth={setWidth}
              setHeight={setHeight}
              tabValue={tabValue}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              onCloseFilter={handleShowFilter} // Pass the close function
            />
          )}
          <TabContent activeTab={tab}>
            <TabPane tabId={tab} className="dashboard-table small-scroll">
              <div className="quick-filter">
                <Formik
                  initialValues={filterValue}
                  onSubmit={() => { }}
                  enableReinitialize
                >
                  {({ setFieldValue, values }) => (
                    <Form className="form-row">
                      <Col md={3}>
                        <label htmlFor="cityIds">Select City</label>
                        <ReactSelect
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          name="cityIds"
                          value={
                            values?.cityIds?.length != undefined
                              ? apiDataList?.filter((option: any) =>
                                values.cityIds?.includes(option.value)
                              )
                              : null
                          }
                          onChange={(selectedOptions: any) => {
                            const selectedValues = selectedOptions.map((option: any) => option.value);
                            setFieldValue("cityIds", selectedValues);
                            updateFilterValue({ cityIds: selectedValues });
                          }}
                          isMulti
                          options={apiDataList}
                        />
                      </Col>
                      <Col md={3}>
                        <label htmlFor="mediaTypes">Select MediaType</label>
                        <ReactSelect
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          name="mediaTypes"
                          onChange={(selectedOptions: any) => {
                            const selectedValues = selectedOptions.map((option: any) => option.value);
                            setFieldValue("mediaTypes", selectedValues);
                            updateFilterValue({ mediaTypes: selectedValues });
                          }}
                          isMulti
                          options={mediaApiDataList}
                          value={
                            values?.mediaTypes?.length != undefined
                              ? mediaApiDataList?.filter((option: any) =>
                                values.mediaTypes?.includes(option.value)
                              )
                              : null
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <label htmlFor="mediaTypesLit">Select Media LIT</label>
                        <ReactSelect
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          name="mediaTypesLit"
                          onChange={(selectedOptions: any) => {
                            const selectedValues = selectedOptions.map((option: any) => option.value);
                            setFieldValue("mediaTypesLit", selectedValues);
                            updateFilterValue({ mediaTypesLit: selectedValues });
                          }}
                          isMulti
                          options={mediaLitApiDataList}
                          value={
                            values?.mediaTypesLit?.length != undefined
                              ? mediaLitApiDataList?.filter((option: any) =>
                                values.mediaTypesLit?.includes(option.value)
                              )
                              : null
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <label htmlFor="mediaStatus">Select Media Status</label>
                        <ReactSelect
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          options={mediaStatusOptions}
                          onChange={(option: any) => {
                            if (option?.value) {
                              setFieldValue("mediaStatus", option.value);
                              updateFilterValue({ mediaStatus: option.value });
                              return
                            }
                            updateFilterValue({ mediaStatus: '' });
                            setFieldValue("mediaStatus", '');
                          }}
                          isClearable={true}
                          value={mediaStatusOptions?.filter((option: any) => {
                            return option?.value === values?.mediaStatus
                          })
                          }
                        />
                      </Col>
                    </Form>
                  )}
                </Formik>
              </div>
              {tableRef && (
                // <DataView
                //   columns={columns()}
                //   rows={[{ data: api }]}
                // />
                <HomeDataView
                  classCSS="dashboard"
                  groupedColumns={[{
                    key: 'grouped.column2',
                    title: 'Media',
                    columnsKeys: ["id",
                      "mediaName",
                      "routeName",
                      "zoneName",
                      "city",
                      "zoneDescription",
                      "startLandmarkDescription",
                      "endLandmarkDescription",
                      "qty",
                      "qtyAvailable",
                      "location",
                      "width",
                      "height",
                      "totalSqFt",
                      "mediaType",
                      "mediaSeriesName",
                      "mediaLITName",
                      "routeLink",
                      "startLatitude",
                      "endLatitude",
                      "status",
                      "locationLink",
                      "agencyName"]
                  }, {
                    key: 'grouped.column3',
                    title: 'Booking',
                    columnsKeys: ['bookingId', 'clientName', 'bookingDateStart', 'bookingEndDate', 'bookingEndDate', 'durationMonth', 'durationDay', 'bookingrate', 'rateAgency1']
                  }, {
                    key: 'grouped.column4',
                    title: 'Landlord',
                    columnsKeys: ['landlordType', 'RatePerYear', 'landlordStartDate', 'landlordEndDate']
                  }]}
                  columns={allDashboardColumns(undefined, Number(tab))}
                  rows={api.data}
                  currentPage={api.pageNo}
                  rowCount={api.total}
                  limit={api.limit}
                  offSearch
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  pagination={pagination}
                  setPagination={setPagination}
                  updatePagination={true}
                />
              )}
            </TabPane>
            <TabPane className="dashboard-table dashboard-table-page small-scroll">
              {tableRef && (
                <DataView
                  columns={columns()}
                  rows={[{ data: api.data.length > 0 ? api.data : [] }]}
                  currentPage={api.pageNo}
                  rowCount={api.total}
                  limit={api.limit}
                />
              )}
            </TabPane>
          </TabContent>
        </>
      }
    />
  );
}
